<template lang="pug">
#root
  md-app.app(:md-scrollbar="false")
    md-app-toolbar.flex.bg-white.md-toolbar
      md-button.md-icon-button.mobile(@click="menuVisible = !menuVisible")
        md-icon menu
      img.header-logo(src="@/assets/images/logo-36Kr.png")
      md-tabs.tabs(md-sync-route)
        md-tab(md-label="游戏开发者峰会", to="/", exact)
        md-tab(md-label="手游出海破新界", to="/campaign/tg2021")
        md-tab(md-label="最新产品与趋势", to="/campaign/gml")
        md-tab(md-label="成就全球强品牌", to="/campaign/competitive_brand")
        md-tab(md-label="跨境电商新篇章", to="/campaign/think_of_retail")
      .spacer
    md-app-drawer(:md-active.sync="menuVisible")
      md-toolbar.md-transparent(md-elevation="0")
        span.md-title
          | 导航
      md-list
        md-list-item(to="/", exact)
          md-icon videogame_asset
          span.md-list-item-text
            | 游戏开发者峰会
        md-list-item(to="/campaign/tg2021")
          md-icon games
          span.md-list-item-text
            | 手游出海破新界
        md-list-item(to="/campaign/gml")
          md-icon trending_up
          span.md-list-item-text
            | 最新产品与趋势
        md-list-item(to="/campaign/competitive_brand")
          md-icon public
          span.md-list-item-text
            | 成就全球强品牌
        md-list-item(to="/campaign/think_of_retail")
          md-icon shopping_cart
          span.md-list-item-text
            | 跨境电商新篇章
    md-app-content.app-container
      .container.cover-container
        img.cover(src="@/assets/images/cover-campaign-game-2022.jpg")
        img.cover-mobile(
          src="@/assets/images/cover-campaign-game-2022-mobile.jpg"
        )
        p.cover-text
          | 2022 Google 游戏开发者峰会聚焦最新产品和解决方案，助力开发者和发行商为玩家打造优质的沉浸式游戏世界，实现业务持续增长。
      .fixed-container
        h2#videos.header-text(ref="videos")
          | 主题演讲
          .header-split
        .videos-container
          .videos
            video-card(
              title="2022 Google 游戏开发者峰会主题演讲",
              videoUrl="https://cdn.google.36kr.com/videos/GGDS22_101_CHS.mp4"
            )
              .content-details
                .speaker
                  img.avatar(
                    src="@/assets/images/game2022_avatars/Belinda Langner.jpg"
                  )
                  .details
                    h3.name
                      | Belinda Langner
                    p
                      | Group Product Manager, Google
                .speaker
                  img.avatar(
                    src="@/assets/images/game2022_avatars/Careen Yapp.jpg"
                  )
                  .details
                    h3.name
                      | Careen Yapp
                    p
                      | Strategic Business Development, Google
                .speaker
                  img.avatar(
                    src="@/assets/images/game2022_avatars/Greg Hartrell.jpg"
                  )
                  .details
                    h3.name
                      | Greg Hartrell
                    p
                      | Product Director, Games on Play/Android, Google
              .content-details
                .speaker
                  img.avatar(
                    src="@/assets/images/game2022_avatars/Jack Buser.jpg"
                  )
                  .details
                    h3.name
                      | Jack Buser
                    p
                      | Director of Game Industry Solutions, Google
                .speaker
                  img.avatar(
                    src="@/assets/images/game2022_avatars/Leo Olebe.jpg"
                  )
                  .details
                    h3.name
                      | Leo Olebe
                    p
                      | Managing Director, Play Partnerships, Games, Google
                .speaker
                  img.avatar(
                    src="@/assets/images/game2022_avatars/Nash Islam.jpg"
                  )
                  .details
                    h3.name
                      | Nash Islam
                    p
                      | Head of Global Strategy, AdMob and Sell-Side Apps, Google
        h2.header-text
          | 移动游戏分论坛
          .header-split
        .videos-container
          .videos
            video-card(
              videoUrl="https://cdn.google.36kr.com/videos/GGDS22_207_CHS.mp4",
              title="Android Game Development Kit 的最新动态"
            )
              .content-details
                .speaker
                  img.avatar(
                    src="@/assets/images/game2022_avatars/Scott Carbon-Ogden.jpg"
                  )
                  .details
                    h3.name
                      | Scott Carbon-Ogden
                    p
                      | Product Manager, Google
            video-card(
              videoUrl="https://cdn.google.36kr.com/videos/GGDS22_208_CHS.mp4",
              title="AGDK：利用 Android 工具优化游戏"
            )
              .content-details
                .speaker
                  img.avatar(
                    src="@/assets/images/game2022_avatars/Philip Taylor.jpg"
                  )
                  .details
                    h3.name
                      | Philip Taylor
                    p
                      | Partner Developer Advocate, Games, Google
            video-card(
              videoUrl="https://cdn.google.36kr.com/videos/GGDS22_209_CHS.mp4",
              title="AGDK：推出 Memory Advice API & 如何诊断低内存问题"
            )
              .content-details
                .speaker
                  img.avatar(
                    src="@/assets/images/game2022_avatars/Victor Repetskyi.jpg"
                  )
                  .details
                    h3.name
                      | Victor Repetskyi
                    p
                      | Staff Software Engineer, Google
                .speaker
                  img.avatar(
                    src="@/assets/images/game2022_avatars/Bill Bilodeau.jpg"
                  )
                  .details
                    h3.name
                      | Bill Bilodeau
                    p
                      | Developer Relations Engineer, Google
            video-card(
              videoUrl="https://cdn.google.36kr.com/videos/GGDS22_210_CHS.mp4",
              title="AGDK：利用 Android GPU Inspector 优化 GPU 占用量"
            )
              .content-details
                .speaker
                  img.avatar(
                    src="@/assets/images/game2022_avatars/Pascal Muetschard.jpg"
                  )
                  .details
                    h3.name
                      | Pascal Muetschard
                    p
                      | Software Engineer, Google
                .speaker
                  img.avatar(
                    src="@/assets/images/game2022_avatars/Jaeho Choi.jpg"
                  )
                  .details
                    h3.name
                      | Jaeho Choi
                    p
                      | Lead Graphics Programmer, Krafton NEW STATE Mobile
            video-card(
              videoUrl="https://cdn.google.36kr.com/videos/GGDS22_211_CHS.mp4",
              title="可折叠设备上的游戏"
            )
              .content-details
                .speaker
                  img.avatar(
                    src="@/assets/images/game2022_avatars/Christopher Foley.jpg"
                  )
                  .details
                    h3.name
                      | Christopher Foley
                    p
                      | Developer Advocate, Google
            video-card(
              videoUrl="https://cdn.google.36kr.com/videos/GGDS22_212_CHS.mp4",
              title="借助 Play Asset Delivery 向用户交付优化过的资源"
            )
              .content-details
                .speaker
                  img.avatar(
                    src="@/assets/images/game2022_avatars/Andrew Giugliano.jpg"
                  )
                  .details
                    h3.name
                      | Andrew Giugliano
                    p
                      | Developer Advocate, Google
                .speaker
                  img.avatar(
                    src="@/assets/images/game2022_avatars/Shenshen Cui.jpg"
                  )
                  .details
                    h3.name
                      | Shenshen Cui
                    p
                      | Developer Advocate, Google
            video-card(
              videoUrl="https://cdn.google.36kr.com/videos/GGDS22_214_CHS.mp4",
              title="Google Play 游戏电脑版：提高用户覆盖面、互动度和 ROI"
            )
              .content-details
                .speaker
                  img.avatar(
                    src="@/assets/images/game2022_avatars/Arjun Dayal.jpg"
                  )
                  .details
                    h3.name
                      | Arjun Dayal
                    p
                      | Group Product Manager, Google
                .speaker
                  img.avatar(
                    src="@/assets/images/game2022_avatars/Neil Garde.jpg"
                  )
                  .details
                    h3.name
                      | Neil Garde
                    p
                      | Product Manager, Google
                .speaker
                  img.avatar(
                    src="@/assets/images/game2022_avatars/Shala Seo.jpg"
                  )
                  .details
                    h3.name
                      | Shala Seo
                    p
                      | Strategic Partner Development Manager, Google
            video-card(
              videoUrl="https://cdn.google.36kr.com/videos/GGDS22_215_CHS.mp4",
              title="Google Play 游戏电脑版：开发者历程"
            )
              .content-details
                .speaker
                  img.avatar(
                    src="@/assets/images/game2022_avatars/Maggie Luan.jpg"
                  )
                  .details
                    h3.name
                      | Maggie Luan
                    p
                      | Publishing Channel Manager, Moonton
                .speaker
                  img.avatar(
                    src="@/assets/images/game2022_avatars/Shenshen Cui.jpg"
                  )
                  .details
                    h3.name
                      | Shenshen Cui
                    p
                      | Developer Advocate, Google
            video-card(
              videoUrl="https://cdn.google.36kr.com/videos/GGDS22_216_CHS.mp4",
              title="Google Play 游戏电脑版：用户登录易如 1-2-3"
            )
              .content-details
                .speaker
                  img.avatar(
                    src="@/assets/images/game2022_avatars/Andrew Giugliano.jpg"
                  )
                  .details
                    h3.name
                      | Andrew Giugliano
                    p
                      | Developer Advocate, Google
                .speaker
                  img.avatar(
                    src="@/assets/images/game2022_avatars/Vlad Radu.jpg"
                  )
                  .details
                    h3.name
                      | Vlad Radu
                    p
                      | Product Manager, Google
            video-card(
              videoUrl="https://cdn.google.36kr.com/videos/GGDS22_217_CHS.mp4",
              title="“覆盖面和设备”及 Android Vitals 中针对游戏的改进"
            )
              .content-details
                .speaker
                  img.avatar(
                    src="@/assets/images/game2022_avatars/Lauren Mytton.jpg"
                  )
                  .details
                    h3.name
                      | Lauren Mytton
                    p
                      | Product Manager, Google
                .speaker
                  img.avatar(
                    src="@/assets/images/game2022_avatars/Ben Gable.jpg"
                  )
                  .details
                    h3.name
                      | Ben Gable
                    p
                      | Developer Relations, Google
            video-card(
              videoUrl="https://cdn.google.36kr.com/videos/GGDS22_218_CHS.mp4",
              title="让多样化和包容性根植于游戏设计和发布中"
            )
              .content-details
                .speaker
                  img.avatar(
                    src="@/assets/images/game2022_avatars/Tiff Tang.jpg"
                  )
                  .details
                    h3.name
                      | Tiff Tang
                    p
                      | Business Development Manager, Google
            video-card(
              videoUrl="https://cdn.google.36kr.com/videos/GGDS22_219_CHS.mp4",
              title="利用 Play Integrity API 保护游戏"
            )
              .content-details
                .speaker
                  img.avatar(
                    src="@/assets/images/game2022_avatars/Daniel Galpin.jpg"
                  )
                  .details
                    h3.name
                      | Daniel Galpin
                    p
                      | Developer Relations Engineer, Google
            video-card(
              videoUrl="https://cdn.google.36kr.com/videos/GGDS22_221_CHS.mp4",
              title="利用 Crashlytics for Unity 增强手游的稳定性"
            )
              .content-details
                .speaker
                  img.avatar(
                    src="@/assets/images/game2022_avatars/Sam Edson.jpg"
                  )
                  .details
                    h3.name
                      | Sam Edson
                    p
                      | Software Engineer, Google
                .speaker
                  img.avatar(
                    src="@/assets/images/game2022_avatars/Marc Richards.jpg"
                  )
                  .details
                    h3.name
                      | Marc Richards
                    p
                      | Software Engineer, Google
            video-card(
              videoUrl="https://cdn.google.36kr.com/videos/GGDS22_223_CHS.mp4",
              title="借助 Google 广告解决方案，让新作赢在起点"
            )
              .content-details
                .speaker
                  img.avatar(
                    src="@/assets/images/game2022_avatars/Jennifer Lui.jpg"
                  )
                  .details
                    h3.name
                      | Jennifer Lui
                    p
                      | Product Manager, Google
                .speaker
                  img.avatar(
                    src="@/assets/images/game2022_avatars/Katie Funk.jpg"
                  )
                  .details
                    h3.name
                      | Katie Funk
                    p
                      | Global Product Lead, Google
        h2.header-text
          | 云游戏和基础架构分论坛
          .header-split
        .videos-container
          .videos
            video-card(
              videoUrl="https://cdn.google.36kr.com/videos/GGDS22_201_CHS.mp4",
              title="听 Embark Studios 畅谈开源游戏后端技术"
            )
              .content-details
                .speaker
                  img.avatar(
                    src="@/assets/images/game2022_avatars/Mark Mandel.jpg"
                  )
                  .details
                    h3.name
                      | Mark Mandel
                    p
                      | Developer Advocate, Google Cloud
                .speaker
                  img.avatar(
                    src="@/assets/images/game2022_avatars/Luna Duclos.jpg"
                  )
                  .details
                    h3.name
                      | Luna Duclos
                    p
                      | Lead Backend Engineer, Embark Studios
            video-card(
              videoUrl="https://cdn.google.36kr.com/videos/GGDS22_202_CHS.mp4",
              title="在 GCP 上成功构建实时数据管道"
            )
              .content-details
                .speaker
                  img.avatar(
                    src="@/assets/images/game2022_avatars/Vishu Cheruku.jpg"
                  )
                  .details
                    h3.name
                      | Vishu Cheruku
                    p
                      | Cloud Customer Engineer, Gaming, Google Cloud
                .speaker
                  img.avatar(
                    src="@/assets/images/game2022_avatars/Mike Nadal.jpg"
                  )
                  .details
                    h3.name
                      | Mike Nadal
                    p
                      | Cloud Customer Engineer, Gaming, Google Cloud
            video-card(
              videoUrl="https://cdn.google.36kr.com/videos/GGDS22_203_CHS.mp4",
              title="营造健康聊天环境 - 开源恶意言论检测框架"
            )
              .content-details
                .speaker
                  img.avatar(
                    src="@/assets/images/game2022_avatars/Dan Zaratsian.jpg"
                  )
                  .details
                    h3.name
                      | Dan Zaratsian
                    p
                      | AI/ML Solutions Architect, Gaming, Google Cloud
                .speaker
                  img.avatar(
                    src="@/assets/images/game2022_avatars/Patrick Smith.jpg"
                  )
                  .details
                    h3.name
                      | Patrick Smith
                    p
                      | Machine Learning Specialist, Google Cloud
            video-card(
              videoUrl="https://cdn.google.36kr.com/videos/GGDS22_204_CHS.mp4",
              title="Project Density - 构建大型元宇宙背后的技术挑战"
            )
              .content-details
                .speaker
                  img.avatar(
                    src="@/assets/images/game2022_avatars/Rob Whitehead.jpg"
                  )
                  .details
                    h3.name
                      | Rob Whitehead
                    p
                      | Co-Founder & Chief Product Officer, Improbable
                .speaker
                  img.avatar(
                    src="@/assets/images/game2022_avatars/Sam Weeks.jpg"
                  )
                  .details
                    h3.name
                      | Sam Weeks
                    p
                      | Cloud Customer Engineer, Google Cloud
            video-card(
              videoUrl="https://cdn.google.36kr.com/videos/GGDS22_205_CHS.mp4",
              title="将运行中的游戏服务迁移到 Google Cloud"
            )
              .content-details
                .speaker
                  img.avatar(
                    src="@/assets/images/game2022_avatars/Yutty Kawahara.jpg"
                  )
                  .details
                    h3.name
                      | Yutty Kawahara
                    p
                      | Customer Engineer for Gaming, Google Cloud
                .speaker
                  img.avatar(
                    src="@/assets/images/game2022_avatars/Tatsuya Koizumi.jpg"
                  )
                  .details
                    h3.name
                      | Tatsuya Koizumi
                    p
                      | IT Infrastracture Engineer,Assistant Manager,System Department, Bandai Namco Online
            video-card(
              videoUrl="https://cdn.google.36kr.com/videos/GGDS22_206_CHS.mp4",
              title="使用 Spanner 和 Cloud Run 构建游戏内货币平台"
            )
              .content-details
                .speaker
                  img.avatar(
                    src="@/assets/images/game2022_avatars/Shunsaku Takashima.jpg"
                  )
                  .details
                    h3.name
                      | Shunsaku Takashima
                    p
                      | Engineering Group Leader, EXNOA LLC
                .speaker
                  img.avatar(
                    src="@/assets/images/game2022_avatars/Yuki Ogura.jpg"
                  )
                  .details
                    h3.name
                      | Yuki Ogura
                    p
                      | Lead Software Engineer, EXNOA LLC

      .footer
        img.footer-logo(src="@/assets/images/logo-36Kr-grey.png")
  .hidden(
    :class="{ 'share-mobile': isShare }",
    @click="() => { isShare = false; }"
  )
    img.share-img(src="~@/assets/images/share.png")
  .shares
    .share-icon-container(@click="share")
      md-icon.share-icon share
      .hidden(
        :class="{ 'share-pc': isShare }",
        @click="() => { isShare = false; }"
      )
        img(src="~@/assets/images/share-pc.png", width="150px")
    .share-icon-container(@click="like")
      md-icon.share-icon(:class="{ active: isActive }") favorite
</template>

<script>
import VideoCard from "../components/video-card.vue";

export default {
  name: "Game",
  components: { VideoCard },
  data() {
    return {
      isActive: false,
      isStar: false,
      isShare: false,
      isVideoToEnd: false,
      menuVisible: false,
    };
  },
  methods: {
    like() {
      this.$pushEvent("like", {});
      this.isActive = !this.isActive;
    },
    star() {
      this.isStar = !this.isStar;
    },
    share() {
      this.$pushEvent("share", {});
      this.isShare = !this.isShare;
    },
    openUri(uri) {
      this.$pushEvent("openUri", { url: uri }).then(() => {
        window.location.assign(uri);
        // const ua = navigator.userAgent.toLowerCase();
        // if (
        //   (ua.indexOf("safari") !== -1 && ua.indexOf("chrome") === -1) ||
        //   ua.indexOf("micromessenger") !== -1
        // ) {
        //   window.location.assign(uri);
        // } else {
        //   window.open(uri, "_blank");
        // }
      });
    },
  },
  mounted() {
    this.$pushEvent("visit", {
      route: "ggds2022",
      source: window.location.search,
    });

    // This is a hack to Chromium bug
    if (window.location.hash.length > 0) {
      const hash = window.location.hash.substring(1);
      document.getElementById(hash).scrollIntoView();
      // hack to Safari bug
      const ua = navigator.userAgent.toLowerCase();
      if (
        (ua.indexOf("safari") !== -1 && ua.indexOf("chrome") === -1) ||
        ua.indexOf("micromessenger") !== -1
      ) {
        window.setTimeout(() => {
          document.getElementById(hash).scrollIntoView();
        }, 100);
      }
    }
  },
};
</script>

<style lang="stylus" scoped>
img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

h2 {
  text-align: center;
}

.header-split {
  width: 100%;
  height: 3px;
  margin-top: 8px;
  background: #448aff;
}

h3 {
  font-size: 15px;
}

a {
  cursor: pointer;
}

.report-h2 {
  font-size: 19px;
}

.tabs {
  @media screen and (min-width: 1280px) {
    display: block;
  }

  display: none;
}

.md-toolbar {
  min-height: 48px;
}

.md-toolbar .md-tabs {
  margin: 0 !important;
}

.pc {
  @media screen and (min-width: 1280px) {
    display: block;
  }

  display: none;
}

.mobile {
  @media screen and (min-width: 1280px) {
    display: none;
  }

  display: block;
}

.arrow-container {
  @media screen and (min-width: 1280px) {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 50px;
  }

  display: none;
}

.arrow {
  @media screen and (min-width: 1280px) {
    top: 10px;
    z-index: 9;
    display: block;
    cursor: pointer;
  }
}

.arrow-back {
  margin-right: 10px;
}

.arrow-forward {
  margin-left: 10px;
}

.arrow-icon {
  background-color: #cccccc;
  width: 10px;
  height: 10px;
  border-radius: 5px;

  &.active {
    background-color: #000000;
  }
}

.share-img {
  width: 200px;
  position: absolute;
  right: 0;
  top: 0;
}

.hidden {
  display: none;
}

.show-player {
  display: flex;
}

.share-pc {
  @media screen and (min-width: 1024px) {
    display: block;
    position: absolute;
    left: -150px;
    top: -90px;
  }
}

.share-mobile {
  @media screen and (max-width: 1024px) {
    display: block;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.3;
  }
}

.shares {
  position: fixed;
  background-color: #fff;
  right: 0;
  top: 50%;
  display: flex;
  flex-direction: column;
  border: 1px solid;
  border-radius: 10px 0 0 10px;

  @media screen and (max-width: 1280px) {
    top: 30%;
  }
}

.share-icon-container {
  margin: 10px;
  cursor: pointer;
}

.share-icon {
  color: #000 !important;
}

.close-icon {
  margin: 0 0 0 auto;
  cursor: pointer;
}

.active {
  color: #ff0000 !important;
}

.star {
  color: #ffcc00 !important;
}

.my-4 {
  margin-bottom: 20px;
}

.my-8 {
  margin-bottom: 40px;
}

.app {
  width: 100%;
  max-height: 100vh;
  position: absolute;
}

.app-container {
  max-width: 100vw;
  max-height: 100vh;
}

.flex {
  display: flex;
}

.spacer {
  flex: 1;
}

.header-logo {
  height: 24px;
}

.padding-btn {
  padding: 5px 10px;
}

.container {
  width: 100%;
  margin: 0 auto;
}

.fixed-container {
  max-width: 1200px;
  margin: 0 auto;
}

.cover-container {
  padding: 10px 0 50px 0;

  @media screen and (min-width: 1280px) {
    padding: 50px 200px;
  }

  background: #fff;
}

.cover {
  display: none;
  width: 100vw;
  margin: auto;

  @media screen and (min-width: 1280px) {
    display: block;
  }
}

.cover-text {
  padding: 0 20px;
  line-height: 1.8;
  text-align: left;

  @media screen and (min-width: 1280px) {
    padding: 0 50px;
    font-size: 20px;
  }
}

.header-text {
  padding: 16px 20px;
  line-height: 1.8;
  text-align: left;

  @media screen and (min-width: 1280px) {
    padding: 16px 50px;
    font-size: 20px;
  }

  margin: 0;
}

.cover-mobile {
  display: block;
  margin: 10px auto 60px auto;

  @media screen and (min-width: 1280px) {
    display: none;
  }
}

.md-content {
  padding: 0;
}

.article-content-title {
  color: rgb(117, 117, 117);
}

.bg-grey {
  background-color: #f2f2f2;
}

.bg-white {
  background-color: #fff;
}

.md-card-media img {
  border-radius: 32px 32px 0 0;
}

.span-btn:hover {
  text-decoration: none;
}

.span-text {
  display: inline-block;
  line-height: 24px;
  vertical-align: middle;
}

.slide-container {
  @media screen and (min-width: 1280px) {
    width: 800px;
  }

  width: 90%;
  margin: 0 auto;
}

.article-card {
  display: flex;
  cursor: pointer;
  margin: 20px 0;
}

.article-image {
  @media screen and (min-width: 1024px) {
    width: 200px;
    height: calc(200px / 4 * 3);
    border-radius: 32px;
    margin: 0 30px 0 0;
  }

  width: 140px;
  height: calc(140px / 4 * 3);
  border-radius: 16px;
  margin: 0 15px 0 0;
}

.article-content {
  flex: 4;
}

.flex-section {
  display: flex;
  padding: 40px 0;

  @media screen and (min-width: 1280px) {
    flex-direction: row;
    padding: 60px 0;
    justify-content: center;
    align-items: center;
    max-width: 1000px;
    margin: 0 auto;
  }

  flex-direction: column;
}

.section-description {
  margin: 0 20px 10px 20px;
}

.section-image {
  max-width: 90%;

  @media screen and (min-width: 1280px) {
    max-width: 50%;
  }

  width: auto;
  height: auto;
  justify-self: flex-start;
  align-self: flex-start;
  margin: 0 auto;
}

.section-content {
  @media screen and (min-width: 1280px) {
    width: 480px;
    padding: 0 40px;
  }

  padding: 0 10px;
  margin: auto 0;
}

.lg-btn {
  width: 220px;
  margin: 20px auto;
  display: block;
}

.flex-reverse {
  @media screen and (min-width: 1280px) {
    flex-direction: row-reverse;
  }

  flex-direction: column;
}

.footer {
  background-color: #f5f5f5;
  padding: 20px;

  .footer-logo {
    margin: 0 auto 0 auto;
    height: 20px;
    display: block;
  }
}

h3.title {
  line-height: 1.4;
}
</style>
